<template>
  <div class="flex items-center">
    <a
      href="#"
      class="btn btn-w mr-2"
      @click.prevent="$modal.toggle({ component: 'Editor' })"
    >
      <i class="mr-2"><img src="@/assets/howto.svg" alt="" /></i>
      <span>
        <span class="block text-xs text-gray-400">遇到困難了嗎</span>
        <span class="block text-sm tracking-widest">策展教學</span>
      </span>
    </a>
    <a
      v-if="$route.name === 'Editor.Profile'"
      href="#"
      class="btn btn-w mr-2"
      @click.prevent="onPrev"
      >回上一步</a
    >
    <a href="#" class="btn btn-w mr-2" @click.prevent="onPreview">預覽展間</a>
    <a href="#" class="btn btn-w mr-2" @click.prevent="onSave">儲存設定</a>

    <a
      v-if="$route.name === 'Editor.Space'"
      href="#"
      class="btn btn-w btn-primary"
      @click.prevent="onNext"
      >下一步</a
    >
    <a
      v-if="$route.name === 'Editor.Profile'"
      href="#"
      class="btn btn-w btn-primary"
      @click.prevent="onPublish"
      >送出審核</a
    >
  </div>
</template>

<script>
import { inject } from 'vue';
import { useRouter } from 'vue-router';

import { ElMessageBox, ElMessage } from 'element-plus';
export default {
  setup() {
    const router = useRouter();

    const exhibId = inject('exhibId');
    const publish = inject('publish');
    const saveStep = inject('saveStep');

    const onNext = () => {
      saveStep().then((res) => {
        router.push({
          name: 'Editor.Profile',
          id: exhibId,
        });
      });
    };
    const onPrev = () => {
      router.push({
        name: 'Editor.Space',
        id: exhibId,
      });
    };

    const onSave = () => {
      saveStep()
        .then((res) => {
          ElMessageBox.alert('成功儲存', { center: true });
        })
        .catch(() => {
          ElMessage({
            type: 'error',
            message: `請完整填寫資料`,
          });
        });
    };

    const onPreview = () => {
      saveStep().then(() => {
        router.push({ name: 'Editor.Preview', params: { id: exhibId } });
      });
    };
    const onPublish = () => {
      publish();
    };
    return { onNext, onPrev, onSave, onPreview, onPublish };
  },
};
</script>

<style></style>
