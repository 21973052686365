<template>
  <div class="flex flex-none">
    <FormLabel>
      <template #title>展間尺寸設定 </template>
      <template #btn>
        <El-Button
          class="ml-2 w-5"
          type="text"
          @click="
            $modal.toggle({
              component: 'Size',
              attrs: { title: '展間尺寸設定' },
            })
          "
        >
          <img src="@/assets/howto.svg" alt="" />
        </El-Button>
      </template>
      <Size :size="areaData" @update="updateArea" />
    </FormLabel>

    <FormLabel class="ml-8">
      <template #title>展間高度設定 </template>
      <template #btn>
        <El-Button
          class="ml-2 w-5"
          type="text"
          @click="
            $modal.toggle({
              component: 'Depth',
              attrs: { title: '展間高度設定' },
            })
          "
        >
          <img src="@/assets/howto.svg" alt="" />
        </El-Button>
      </template>
      <Depth :modelValue="extra.depth" @update:modelValue="updateExtra" />
    </FormLabel>
    <FormLabel class="ml-8">
      <template #title>地面設定 </template>
      <template #btn>
        <El-Button
          class="ml-2 w-5"
          type="text"
          @click="
            $modal.toggle({
              component: 'Floor',
              attrs: { title: '地面設定' },
            })
          "
        >
          <img src="@/assets/howto.svg" alt="" />
        </El-Button>
      </template>
      <Floor :modelValue="extra.floor" @update:modelValue="updateExtra" />
    </FormLabel>
    <FormLabel class="ml-8">
      <template #title>入口設定 </template>
      <template #btn>
        <El-Button
          class="ml-2 w-5"
          type="text"
          @click="
            $modal.toggle({
              component: 'Entry',
              attrs: { title: '入口設定' },
            })
          "
        >
          <img src="@/assets/howto.svg" alt="" />
        </El-Button>
      </template>
      <select class="form-select !w-[120px]" v-model="extra.start">
        <option :value="'1'">展場外部</option>
        <option :value="'2'">展場內部</option>
      </select>
    </FormLabel>

    <FormLabel class="ml-8">
      <template #title>展間編輯器 </template>
      <template #btn>
        <El-Button
          class="ml-2 w-5"
          type="text"
          @click="
            $modal.toggle({
              component: 'Blueprint',
              attrs: { title: '展間編輯器' },
            })
          "
        >
          <img src="@/assets/howto.svg" alt="" />
        </El-Button>
      </template>
      <div class="flex">
        <El-Button
          class="dark"
          size="mini"
          :class="status === 'walling' ? '!bg-primary ' : ''"
          @click.prevent="toggleStatus('walling', 'dragging')"
        >
          <!-- <i><img src="@/assets/menu.svg" alt="" /></i> -->
          繪製展牆</El-Button
        >
        <El-Button
          class="dark"
          size="mini"
          :disabled="status === 'walling' || !chosen.state.id"
          @click.prevent="toggleEdit()"
        >
          <!-- <i><img src="@/assets/menu.svg" alt="" /></i> -->
          編輯物件</El-Button
        >
        <El-Button
          class="dark"
          size="mini"
          :disabled="status === 'walling' || !chosen.state.id"
          @click.prevent="remove"
        >
          <!-- <i><img src="@/assets/menu.svg" alt="" /></i> -->
          刪除物件</El-Button
        >
        <El-dropdown class="ml-[10px]" @command="onTemplateSelect">
          <El-Button class="dark" size="mini" :disabled="status === 'walling'">
            <!-- <i><img src="@/assets/menu.svg" alt="" /></i> -->
            載入模板</El-Button
          >

          <template #dropdown>
            <El-dropdown-menu>
              <El-dropdown-item
                v-for="temp in templates"
                :command="temp.id"
                :key="temp.id"
                >{{ temp.title }}</El-dropdown-item
              >
            </El-dropdown-menu>
          </template>
        </El-dropdown>
      </div>
    </FormLabel>
    <FormLabel class="ml-auto">
      <template #title> </template>
      <div class="flex items-center">
        <a href="#" @click.prevent="toggleFull" class="px-2 mt-2 block">
          <El-icon color="#fff" :size="20">
            <img class="" src="@/assets/full-screen.svg" alt="" />
          </El-icon>
        </a>
      </div>
    </FormLabel>
  </div>
</template>

<script>
import { inject, reactive } from 'vue';
import FormLabel from '../_FormLabel.vue';
import templatesJson from './templates.json';
import {
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElMessageBox,
  ElIcon,
} from 'element-plus';
import { STAGE_SIZE } from '@/utils/Config';
import Size from './_Size.vue';
import Depth from './_Depth.vue';
import Floor from './_Floor.vue';
export default {
  components: {
    ElButton,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    Size,
    Depth,
    Floor,
    FormLabel,
    ElIcon,
  },
  setup() {
    const { remove } = inject('itemData');
    const {
      areaData,
      updateArea,
      depth,
      updateDepth,
      extra,
      updateExtra,
      status,
      toggleStatus,
      loadTemp,
    } = inject('blueprint');

    const chosen = inject('chosen');
    const toggleEdit = inject('toggleEdit');

    const { toggleFull } = inject('full');
    const stageSize = reactive({ ...STAGE_SIZE });
    const templates = reactive([...templatesJson]);
    const onTemplateSelect = (key) => {
      const temp = templates.find((x) => x.id === key);

      ElMessageBox.confirm('此操作會移除現有展覽牆面', { center: true }).then(
        () => {
          loadTemp(temp.blueprint);
        }
      );
    };
    const toggle = () => {
      var el = document.querySelector('body');

      el.onclick = function () {
        el.classList.toggle('active');
      };
    };
    return {
      onTemplateSelect,
      templates,
      chosen,
      depth,
      updateDepth,
      status,
      toggleEdit,
      toggleStatus,
      remove,
      stageSize,
      areaData,
      updateArea,
      extra,
      updateExtra,

      toggleFull,
    };
  },
};
</script>
