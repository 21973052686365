import Konva from 'konva';

import { GRID, STAGE_SIZE } from '@/utils/Config';

export default class Door extends Konva.Rect {
  constructor(params) {
    const { config, stage, layer } = params;

    super({
      width: GRID * 8,
      height: 30,
      fill: '#999999',
      ...config,
    });

    this.stage = stage;
    this.layer = layer;
    // 要加上檢察 給匯入用

    this.gridSize = GRID;

    this.layer.add(this);
  }
}
