<template>
  <El-form label-position="top" :model="form" ref="formRef">
    <El-form-item label="外牆顏色" prop="color" class="text-center">
      <div class="flex items-center">
        <span class="font-bold px-4 border border-gray-500 h-[40px]">
          {{ form.exterior }}</span
        >
        <El-Color-Picker v-model="form.exterior" />
      </div>
    </El-form-item>
    <El-form-item label="內牆顏色材質" prop="texture" class="text-center">
      <div class="flex items-center">
        <span class="font-bold px-4 border border-gray-500 h-[40px]">{{
          form.color
        }}</span>
        <El-Color-Picker v-model="form.color" />
        <Texture type="wall" v-model="form.texture"></Texture>
      </div>
    </El-form-item>
    <p class="text-xs font-base text-gray-400 text-center py-2">
      選取材質將會取代顏色設定。
    </p>
  </El-form>
  <div class="action flex justify-center my-8">
    <El-button @click.prevent="submit" type="primary">儲存設定並關閉</El-button>
  </div>
</template>

<script>
import { reactive, inject, watch } from 'vue';
import Texture from '@/pages/index/components/texture/Index.vue';
import { ElColorPicker, ElButton, ElFormItem, ElForm } from 'element-plus';

export default {
  components: {
    ElColorPicker,
    ElButton,
    ElFormItem,
    ElForm,
    Texture,
  },
  setup() {
    const dialog = inject('dialog');

    const { extra, updateExtra } = inject('blueprint');

    const form = reactive({
      ...extra.outer,
    });

    const submit = () => {
      updateExtra({ outer: { ...form } });
      dialog.toggle();
    };
    return { form, submit, extra, updateExtra };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  .el-form-item__label {
    @apply text-center;
  }
  .el-form-item__content {
    @apply inline-flex;
  }
}
</style>
