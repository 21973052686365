<template>
  <div class="flex flex-col flex-grow relative">
    <control class="flex flex-none" />
    <hr class="my-4 border-gray-900 flex-none" />
    <div
      ref="canvasRef"
      class="border-4 border-gray-500 flex-grow h-0 overflow-hidden w-full"
    >
      <div id="container" @drop="createItem" @dragover="onDragover"></div>
    </div>
  </div>
</template>
<script>
import Control from './Control.vue';
import { ref, inject } from 'vue';

export default {
  components: { Control },
  emits: ['onDrop', 'onDragover', 'resize'],
  setup() {
    const { canvasRef, onDragover, createItem } = inject('blueprint');

    return { canvasRef, onDragover, createItem };
  },
};
</script>
