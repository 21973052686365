<template>
  <div class="flex items-center mb-2">
    <el-select
      v-model="form.family"
      class="w-36 rounded-l-md"
      placeholder="字型"
      size="small"
    >
      <template #prefix>
        <div class="pl-2">
          <img src="@/assets/font.svg" class="w-4" alt="" />
        </div>
      </template>
      <el-option
        v-for="font in FONTS"
        :key="font.id"
        :label="font.name"
        :value="font.id"
      />
    </el-select>

    <el-select v-model="form.size" class="w-24" placeholder="尺寸" size="small">
      <template #prefix>
        <div class="pl-2">
          <img src="@/assets/size.svg" class="w-4" alt="" />
        </div>
      </template>
      <el-option v-for="font in SIZES" :key="font.value" :value="font.value" />
    </el-select>

    <el-checkbox-button v-model="form.bold" size="small">
      <img src="@/assets/bold.svg" class="w-4" alt="" />
    </el-checkbox-button>
    <el-radio-group v-model="form.align" class="h-[32px]">
      <el-radio-button label="left">
        <img class="w-[10px] block" src="@/assets/align-left.svg" alt="" />
      </el-radio-button>
      <el-radio-button label="center">
        <img class="w-[10px] block" src="@/assets/align-center.svg" alt="" />
      </el-radio-button>
      <el-radio-button label="right">
        <img class="w-[10px] block" src="@/assets/align-right.svg" alt="" />
      </el-radio-button>
    </el-radio-group>
    <El-Color-Picker v-model="form.color" class="h-[32px]"> </El-Color-Picker>
    <el-select
      v-if="exclude.indexOf('offsetY')"
      v-model="form.offsetY"
      class="w-24 text-center"
      placeholder="位移"
      size="small"
    >
      <template #prefix>
        <div class="pl-2">
          <img src="@/assets/line-height-1.svg" class="w-4" alt="" />
        </div>
      </template>
      <el-option
        v-for="font in OFFSETS"
        :key="font.value"
        :value="font.value"
      />
    </el-select>
  </div>
</template>
<script>
import { reactive, watch } from 'vue';
import { FONTS } from '@/utils/Config';
import _ from 'lodash';
import {
  ElColorPicker,
  ElRadioGroup,
  ElRadioButton,
  ElCheckboxButton,
  ElSelect,
  ElOption,
} from 'element-plus';

export default {
  components: {
    ElColorPicker,
    ElRadioGroup,
    ElRadioButton,
    ElCheckboxButton,
    ElSelect,
    ElOption,
  },
  props: {
    modelValue: {
      type: Object,
    },

    exclude: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const form = reactive({});
    const SIZES = [];
    for (let i = 0; i <= 18; i++) {
      SIZES.push({ value: 10 + i * 2 });
    }
    const OFFSETS = [];
    for (let i = -10; i <= 10; i++) {
      OFFSETS.push({ value: 30 + i * 5 });
    }
    watch(
      () => props.modelValue,
      (val, old) => {
        if (!val || val === old) return;
        Object.assign(form, { ...val });
      },
      {
        immediate: true,
        deep: true,
      }
    );

    watch(
      form,
      (val) => {
        emit('update:modelValue', val);
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return { FONTS, form, SIZES, OFFSETS };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  @apply border-gray-400;
  padding-left: 35px !important;
}
::v-deep .el-checkbox-button__inner {
  @apply p-0 flex items-center justify-center;

  width: 32px;
  height: 32px !important;
  line-height: 32px;
  @apply border-gray-400;
}

::v-deep .el-radio-group {
  @apply flex items-center;
}
::v-deep .el-radio-button__inner {
  @apply px-2  flex items-center justify-center;

  width: 32px;
  height: 32px !important;
  line-height: 32px;
  @apply border-gray-400;
}

.el-checkbox-button.is-checked,
.el-radio-button.is-active {
  img {
    @apply filter invert;
  }
}
::v-deep .el-color-picker__trigger {
  height: 32px;
  width: 32px;

  @apply border-gray-400 rounded-none;
}
</style>
