<template>
  <div class="flex" v-loading="loading">
    <div class="w-[600px]">
      <h3 class="font-bold text-primary mb-2">位置預覽</h3>

      <img class="w-64" :src="$filters.uploadUrl(test)" alt="" />
      <Pos-Canvas
        v-if="!loading"
        :type="'intro'"
        :l="form.l"
        :h="form.h"
        :title="form.title"
        :color="form.color"
        :excerpt="form.excerpt"
        :offset-x="form.offsetX"
        :offset-y="form.offsetY"
        :extra="form.extra"
        :maxheight="extra.depth"
        @update="updateOffset"
        ref="posCanvas"
      />
      <Offset :offset-x="form.offsetX" :offset-y="form.offsetY" />
    </div>

    <div class="flex-grow ml-8">
      <h3 class="text-primary font-bold mb-2">內容</h3>
      <El-form label-position="top" :model="form" ref="formRef" :rules="rules">
        <El-form-item label="文字寬度" prop="l">
          <El-Slider
            :step="30"
            :min="120"
            :max="480"
            v-model="form.l"
          ></El-Slider>
        </El-form-item>

        <!-- <El-form-item label="文字顏色" prop="color">
          <div class="flex">
            <span class="border-light-500 border px-2">{{ form.color }}</span>
            <El-Color-Picker v-model="form.color"> </El-Color-Picker>
          </div>
        </El-form-item> -->

        <El-form-item label="文字標題" prop="title" required>
          <fontBar v-model="form.extra.title" :exclude="['offsetY']"></fontBar>
          <El-Input v-model="form.title" :debounce="1000" />
        </El-form-item>
        <El-form-item label="文字內文" prop="excerpt">
          <fontBar
            v-model="form.extra.excerpt"
            :exclude="['offsetY']"
          ></fontBar>
          <El-Input type="textarea" :rows="6" v-model.debounce="form.excerpt" />
        </El-form-item>
      </El-form>
    </div>
  </div>
  <div class="action flex justify-center my-8">
    <El-button @click.prevent="submit" type="primary" :loading="loading"
      >儲存設定並關閉</El-button
    >
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import PosCanvas from './_Canvas.vue';
import useForm from './useForm';
import Offset from './_Offest.vue';
import { FONTS } from '@/utils/Config';
import fontBar from './_font.vue';
import axios from 'axios';
import {
  // ElColorPicker,
  ElButton,
  ElFormItem,
  ElForm,
  ElInput,
  ElSlider,
  ElMessage,
} from 'element-plus';
export default {
  components: {
    ElButton,
    ElFormItem,
    ElForm,
    ElInput,
    PosCanvas,

    ElSlider,
    Offset,
    fontBar,
  },
  setup() {
    const dialog = inject('dialog');
    const { activeItem } = inject('itemData');
    const chosen = inject('chosen');
    const selection = inject('selection');
    const { extra } = inject('blueprint');
    const posCanvas = ref();

    // var dataURL = intro.toDataURL({ pixelRatio: 3 });
    const rules = {
      title: [
        {
          required: true,
          message: '請輸入標題',
          trigger: 'blur',
        },
        {
          max: 100,
          message: '字數需小於100',
          trigger: 'blur',
        },
      ],
      excerpt: [
        {
          max: 1000,
          message: '字數需小於1000',
          trigger: 'change',
        },
      ],
    };
    const formRef = ref();
    const loading = computed(() => {
      return formLoading.value || selection.loading.value;
    });

    const {
      form,
      updateOffset,
      fetcher,
      loading: formLoading,
    } = useForm({
      id: null,
      l: 240,
      w: 5,
      h: 60,
      offsetX: 0,
      offsetY: 0,
      color: '#000000',
      pic: null,
      type: 4,
      title: 'New title',
      excerpt: '',
      extra: {
        title: {
          family: 'Noto Sans TC',
          size: 24,
          color: '#333333',
          align: 'left',
          bold: true,
        },
        excerpt: {
          family: 'Noto Sans TC',
          size: 16,
          color: '#333333',
          align: 'left',
          bold: false,
        },
      },
    });

    // 如果有選擇狀態 要把已經有的狀態帶入;
    if (chosen.state.id) {
      form.id = chosen.state.id;
      fetcher();
    }
    const dataURItoBlob = (dataURI) => {
      if (typeof dataURI !== 'string') {
        throw new Error('Invalid argument: dataURI must be a string');
      }
      dataURI = dataURI.split(',');
      var type = dataURI[0].split(':')[1].split(';')[0],
        byteString = atob(dataURI[1]),
        byteStringLength = byteString.length,
        arrayBuffer = new ArrayBuffer(byteStringLength),
        intArray = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteStringLength; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([intArray], {
        type: type,
      });
    };

    const test = ref('');
    const submit = async () => {
      let formData = new FormData();
      var url = await posCanvas.value.getIntro().toDataURL({
        pixelRatio: 3,
        mimeType: 'image/png',
      });

      const blob = dataURItoBlob(url);
      formData.append('file', blob, 'intro.png');

      const res = await axios.post(
        process.env.VUE_APP_API_URL + '/upload/chip',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Mobile-Token': process.env.VUE_APP_TOKEN,
          },
        }
      );

      if (res.data?.code !== 1) {
        alert('圖片產生失敗');
        return;
      }
      form.cover = res.data.data.url;

      let isValid = true;
      formRef.value.validate((valid) => {
        isValid = valid;
      });
      if (!isValid) return;

      if (form.id) {
        selection
          .update({
            ...form,
            excerpt: form.excerpt?.trim(),
            extra: JSON.stringify(form.extra),
          })
          .then(() => {
            dialog.toggle();

            if (activeItem.value) {
              activeItem.value.width(form.l);

              activeItem.value.updateTextPos();
              activeItem.value.updateSpot();
            }
          });
      } else {
        selection
          .insert({ ...form, extra: JSON.stringify(form.extra) })
          .then(() => {
            dialog.toggle();
          });
      }
    };

    return {
      form,
      formRef,
      updateOffset,
      submit,
      rules,
      FONTS,
      loading,
      extra,
      posCanvas,
      test,
    };
  },
};
</script>
