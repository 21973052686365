import { reactive, ref } from 'vue';
import WorkService from '@/services/Work';

export default () => {
  let loading = ref(false);
  const data = reactive({});
  const fetcher = (id) => {
    loading.value = true;

    WorkService.find({ id })
      .then((res) => {
        if (res.code !== 1) return;
        Object.assign(data, res.data);
      })
      .finally(() => {
        loading.value = false;
      });
    WorkService.materials({ id })
      .then((res) => {
        if (res.code !== 1 && !res.data.subset.length) return;
        data.materials = res.data.subset.map((x) => {
          return {
            file: x.file,
            summary: x.summary,
            id: x.id,
            type: x.type,
          };
        });
        // console.log(data);
        // data.materials.push({
        //   file: '/upload/doc/2022/01/e3f29cb2d62f73d.mp3',
        //   id: '11',

        //   status: 'Enabled',
        //   summary: '測試音檔',
        //   title: '盆栽',
        //   type: 'File',
        //   work_id: '6251',
        // });
      })
      .finally(() => {
        loading.value = false;
      });
  };
  return {
    fetcher,
    data,
  };
};
