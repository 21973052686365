import Axios from './http';

export default class Blueprint {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */
  static async find(params) {
    return Axios(`/exhib/blueprint`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async save(params) {
    return Axios(`/exhib/update`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
