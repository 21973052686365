import Konva from 'konva';

export default class Stage extends Konva.Stage {
  constructor(params) {
    super({
      ...params,

      draggable: true,
    });
    this._w = params.width;
    this.scaleStep = 1.2;
    this.bind();
  }

  bind() {
    this.on('wheel', (e) => {
      // if (!this.attrs.draggable) return;
      e.evt.preventDefault();
      var oldScale = this.scaleX();

      var pointer = this.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - this.x()) / oldScale,
        y: (pointer.y - this.y()) / oldScale,
      };

      var newScale =
        e.evt.deltaY > 0
          ? oldScale * this.scaleStep
          : oldScale / this.scaleStep;

      this.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.position(newPos);

      // 校正
      this.children[1].children.forEach((x) => {
        if (x.className === 'Transformer' && x.attrs.rotateEnabled) {
          // x 是tr
          x.rotateAnchorOffset(x.attrs._rotateAnchorOffset * this.scaleX());
          x.forceUpdate();
        }
      });
    });
  }
  toggleDraggable(draggable) {
    if (draggable === undefined) return;
    this.draggable(draggable);
  }
  resize(w, h) {
    const scale = w / this._w;
    this.scale({ x: scale, y: scale });

    this.width(w);
    this.height(h);
  }
}
