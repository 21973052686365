<template>
  <el-input
    class="w-[120px]"
    v-model="model"
    type="number"
    placeholder="展場高度"
    :min="min"
    :max="max"
    @change="check"
  >
    <template #suffix>
      <span class="select-none text-xs pr-2"> cm</span>
    </template>
  </el-input>
</template>
<script>
import { computed } from 'vue';
import { ElInput } from 'element-plus';
// import { useVModel } from '@vueuse/core';
// import _ from 'lodash';
export default {
  components: { ElInput },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },

  setup(props, { emit }) {
    // 是否要在這裡做全部item的offset 歸0。
    const min = 300;
    const max = 900;

    // const model = useVModel(props, 'modelValue', emit);

    const model = computed({
      get() {
        return props.modelValue;
      },

      set(val) {
        console.log(typeof val);
        emit('update:modelValue', { depth: parseInt(val) });
      },
    });

    const check = () => {
      if (!model.value || model.value < min) {
        model.value = min;
      } else if (model.value > max) {
        model.value = max;
      }
    };
    return { model, check, min, max };
  },
};
</script>
