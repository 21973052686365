<template>
  <div class="relative w-auto leading-none max-w-lg" v-loading="converting">
    <el-upload
      class="uploader inline-block relative"
      :action="action"
      :accept="accept"
      :show-file-list="false"
      :drag="true"
      :headers="headers"
      :on-success="uploaded"
      :before-upload="onStart"
      :on-error="onError"
      :on-change="onChange"
      :on-progress="onProgress"
    >
      <!-- -->
      <div class="w-full h-full min-h-[100px]">
        <div class="w-full h-full" v-if="url_">
          <img
            v-if="type === 'image'"
            :src="$filters.uploadUrl(url_, 'sm')"
            class="preview object-contain w-full h-full"
          />
          <div v-if="type === 'audio'" class="text-center py-8 bg-gray-100">
            <img class="inline-block w-16" src="@/assets/audio.svg" alt="" />
          </div>
          <div v-if="type === 'video'" class="text-center py-8 bg-gray-100">
            <img class="inline-block w-16" src="@/assets/video.svg" alt="" />
          </div>
          <el-button
            type="primary"
            icon="el-icon-close"
            circle
            class="absolute top-4 right-4"
            @click.stop="remove"
          ></el-button>
        </div>

        <div v-show="!url_ && !loading">
          <i class="el-icon-plus uploader-icon"></i>
        </div>
      </div>
    </el-upload>
    <div
      v-if="loading"
      class="
        bg-white bg-opacity-60
        rounded-lg
        overflow-hidden
        absolute
        flex
        justify-center
        items-center
        w-full
        h-full
        left-0
        top-0
        pointer-events-auto
      "
    >
      <el-progress
        color="#f01d8c"
        :width="60"
        type="circle"
        :percentage="progress"
      />
    </div>
    <p v-if="aspect" class="text-xs text-right">長寬 : {{ aspect }}</p>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from 'vue';
import { ElUpload, ElButton, ElProgress } from 'element-plus';
import { ElMessage } from 'element-plus';
import gcd from '@/utils/Gcd';
export default {
  props: {
    action: {
      type: String,
      default: process.env.VUE_APP_API_URL + '/upload/chip',
    },
    type: {
      type: String,
    },
    accept: {
      type: String,
    },
    id: {
      type: String,
    },
    url: {
      type: String,
    },
    limit: {
      type: Number,
      default: 10,
    },
  },
  components: { ElUpload, ElButton, ElProgress },
  emits: ['update:url', 'update:id', 'uploaded'],
  setup(props, { emit }) {
    const { type, limit } = toRefs(props);

    const headers = reactive({});
    if (process.env.NODE_ENV !== 'production') {
      headers['Mobile-Token'] = process.env.VUE_APP_TOKEN;
    }

    let loading = ref(false);
    const url_ = computed({
      get() {
        return props.url;
      },
      set(val) {
        emit('update:url', val);
      },
    });

    const aspect = ref('');

    watch(
      url_,
      (val) => {
        if (!val) return;

        if (type.value === 'image') {
          const img = new Image();
          img.onload = () => {
            const num = gcd(img.width, img.height);
            aspect.value = img.width / num + '/' + img.height / num;
          };
          img.src = window.$filters.uploadUrl(val, 'sm');
        } else if (type.value === 'video') {
          const video = document.createElement('video');

          video.addEventListener(
            'loadedmetadata',
            () => {
              const num = gcd(video.videoWidth, video.videoHeight);

              aspect.value =
                video.videoWidth / num + '/' + video.videoHeight / num;
            },
            false
          );
          video.src = window.$filters.uploadUrl(val);
        }
      },
      { immediate: true }
    );

    const id_ = computed({
      get() {
        return props.id;
      },
      set(val) {
        emit('update:id', val);
      },
    });

    const remove = (res, file) => {
      url_.value = null;
    };
    const converting = ref(false);
    const uploaded = (res, file) => {
      if (res.code !== 1) {
        ElMessage({ message: '上傳失敗' });
        return;
      }
      if (type.value === 'image') {
        converting.value = true;
        let image = new Image();
        image.src = window.URL.createObjectURL(file.raw);
        image.onload = () => {
          emit('uploaded', image);
          id_.value = res.data.id;
          url_.value = res.data.url;
          converting.value = false;
        };
      } else {
        url_.value = res.data.url || res.data.filename;
        loading.value = false;
      }

      // emit('uploaded',file)
    };
    const onError = (err) => {
      loading.value = false;
      ElMessage({ message: '上傳失敗' });
    };
    const progress = ref(0);
    const onProgress = (e) => {
      progress.value = Math.round(e.percent);
    };
    const onChange = (e) => {
      loading.value = false;
    };

    const onStart = (file) => {
      loading.value = true;
      validate(file);
    };
    const validate = (file) => {
      // const isJPG = file.type === 'image/jpeg';
      const isLt = file.size < limit.value * 1024 * 1024;

      console.log(isLt);
      // if (!isJPG) {
      //   ElMessage({ message: '檔案格式限定為jpg!' });
      // }
      if (!isLt) {
        ElMessage({ message: '不得超過 ' + limit.value + 'MB!' });
      }

      return isLt;
    };
    return {
      headers,
      onError,
      onChange,
      onProgress,
      progress,
      loading,
      converting,
      onStart,
      id_,
      url_,
      uploaded,
      remove,
      aspect,
    };
  },
};
</script>

<style lang="scss">
.uploader {
  @apply w-full;
  .el-upload {
    border: 1px dashed #ccc;
    border-radius: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;

    min-height: 100px;
  }
  .el-upload-dragger {
    @apply w-full h-full relative;
  }

  .uploader .el-upload:hover {
    border-color: #409eff;
  }
  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .preview {
    @apply w-full;
  }
}
</style>
