<template>
  <div class="flex flex-col h-full">
    <div class="control">
      <FormLabel>
        <template #title> 選擇展件 </template>
        <template #btn>
          <El-Button
            class="ml-2 w-5"
            type="text"
            @click.prevent="
              $modal.toggle({
                component: 'Selection',
                attrs: { title: '選擇展件' },
              })
            "
          >
            <img src="@/assets/howto.svg" alt=""
          /></El-Button>
        </template>
      </FormLabel>

      <div class="mt-0 flex items-center" v-if="data">
        <El-Button
          class="dark"
          size="mini"
          @click.prevent="openForm('introForm')"
        >
          <i><img class="!mr-0 invert" src="@/assets/font.svg" alt="" /></i>
          <span class="ml-1">新增文字</span>
        </El-Button>
        <El-Button
          class="dark"
          size="mini"
          @click.prevent="openForm('pictureForm')"
        >
          <i><img class="!mr-0 invert" src="@/assets/video.svg" alt="" /></i>
          <span class="ml-1">新增媒體</span>
        </El-Button>
        <El-Button
          class="dark"
          size="mini"
          @click.prevent="openForm('workList')"
        >
          <i><img class="!mr-0" src="@/assets/menu.svg" alt="" /></i>
          <span class="ml-1">新增展品</span>
        </El-Button>

        <El-dropdown class="ml-auto" trigger="click">
          <El-Button class="min-h-0 !py-1" type="text" size="mini">
            <img class="" src="@/assets/more.svg" alt=""
          /></El-Button>
          <template #dropdown>
            <El-dropdown-menu>
              <El-dropdown-item
                @click.prevent="
                  dialog.toggle({
                    component: 'selectionList',
                    attrs: { title: '匯入選集' },
                  })
                "
                :key="1"
                >匯入其它展覽展品清單</El-dropdown-item
              >

              <El-dropdown-item @click.prevent="exporter" :key="2"
                >匯出目前展覽展品清單</El-dropdown-item
              >
            </El-dropdown-menu>
          </template>
        </El-dropdown>
      </div>
    </div>
    <hr class="my-4 border-gray-900 flex-none" />
    <div class="mb-2">已匯入展品 : {{ data ? data.length : 0 }}</div>
    <div class="flex-grow h-0">
      <c-scrollbar ref="scrollbarRef" width="100%" height="100%" class="pr-4">
        <draggable
          :list="data"
          @start="onStart"
          @sort="onSort"
          :swap-threshold="0.1"
          :animation="200"
          ghostClass="ghost"
          tag="transition-group"
          handle=".handle"
          item-key="id"
        >
          <template #item="{ element, index }">
            <div
              :key="element.id"
              :class="activeClass(element.id)"
              class="chip-item"
            >
              <div
                class="
                  selected
                  absolute
                  top-0
                  left-0
                  w-full
                  h-full
                  border-2
                  pointer-events-none
                "
                :style="{ borderColor: getColor(element.type) }"
              ></div>
              <span class="handle w-6 h-6 flex-none cursor-move">
                <img class="w-10" src="@/assets/handle.svg" alt="" />
              </span>
              <div
                class="ml-4 w-6 flex-none cursor-pointer"
                @click="toggleSort(index)"
              >
                {{ index + 1 }}
              </div>
              <div
                @dblclick="toggleEdit"
                @click="select(element.id, element.type)"
                class="
                  chip-icon
                  cursor-pointer
                  flex-none
                  ml-4
                  w-12
                  text-xs
                  h-12
                  border-gray-600 border
                  flex
                  justify-center
                  items-center
                "
              >
                <img
                  v-if="
                    (element.cover || element.pic) &&
                    (element.type === 2 ||
                      element.type === 3 ||
                      element.type === 5)
                  "
                  class="w-full h-full object-cover"
                  :src="$filters.uploadUrl(element.pic || element.cover, 'sm')"
                  alt=""
                />

                <span v-if="element.type === 4">文字</span>
              </div>
              <div
                @click="select(element.id, element.type)"
                class="px-4 flex-grow truncate cursor-pointer text-sm"
                v-text="element.title"
              ></div>
              <a
                href
                class="text-lg px-2 flex-none"
                @click.prevent="onRemove(element)"
                ><Close class="w-4"
              /></a>
            </div>
          </template>
        </draggable>
      </c-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref, inject, watch } from 'vue';

import draggable from 'vuedraggable';
import {
  ElMessageBox,
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElMessage,
} from 'element-plus';
import { Close } from '@element-plus/icons';
import { getThumb } from '@/utils/Utils';
import { ITEM_TYPE_COLORS } from '@/utils/Config';
import { CScrollbar } from 'c-scrollbar';
import _ from 'lodash';

import FormLabel from '../_FormLabel.vue';
export default {
  components: {
    draggable,
    ElButton,
    Close,
    CScrollbar,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu,
    FormLabel,
  },
  setup(props) {
    const exhibId = inject('exhibId');
    const exporter = () => {
      window.open(
        `${process.env.VUE_APP_UPLOAD_URL}/excel/chip?query=,p.id:${exhibId}`,
        '_blank'
      );
    };
    const uploadUrl = ref(process.env.VUE_APP_UPLOAD_URL + '/');

    const itemData = inject('itemData');
    const selection = inject('selection');
    const blueprint = inject('blueprint');

    const toggleEdit = inject('toggleEdit');
    const chosen = inject('chosen');
    const dialog = inject('dialog');
    const scrollbarRef = ref();

    const isUsed = (id) => {
      return itemData.items.some((x) => x.attrs.id === id);
    };
    const isChosen = (id) => id === chosen.state.id;

    const updateTitle = (title) => {
      selection.updateTitle(title);
    };
    const select = (id, type) => {
      chosen.update({ id, type, scroll: false });
      // itemData.select(id);
    };
    watch(
      chosen.state,
      (val) => {
        if (val.scroll && val.type !== 1) {
          const idx = selection.data.findIndex((x) => x.id === val.id);
          if (idx > -1) {
            scrollTo(idx);
          } else {
            ElMessage({ message: '物件不存在，請刪除後重新拖曳。' });
          }
        }
      },
      {
        deep: true,
      }
    );
    const scrollTo = (idx) => {
      scrollbarRef.value.setScrollTop(74 * idx);
    };

    const onStart = (e) => {
      itemData.onDragstart({
        ...selection.data[e.oldIndex],
        idx: e.oldIndex,
      });
    };

    const toggleSort = async (idx) => {
      const { value } = await ElMessageBox.prompt('請輸入新的順序', '', {
        confirmButtonText: '確認',
        inputValue: idx + 1,
        inputType: 'number',
        cancelButtonText: 'Cancel',
        inputPattern: /^\+?[1-9][0-9]*$/,
        inputErrorMessage: '序號錯誤',
      });

      const ids = await selection.move(idx, value);
      if (itemData.items.length) {
        itemData.updateIdx(ids);
      }
    };

    const onSort = (e) => {
      selection.updateSorter().then((res) => {
        if (itemData.items.length) {
          itemData.updateIdx(res);
        }
      });
    };
    const onCreate = async (e) => {
      if (selection.data.id) {
        await ElMessageBox.confirm('確定要新增展覽?', { center: true });
      }
      //這個會直接更新exhib
      // exhib.createSelection(e);
      selection.create(e);
    };
    const openForm = (itemType) => {
      itemData.unselect();
      chosen.reset();
      dialog.toggle({ component: itemType });
    };
    const onRemove = async (row) => {
      await ElMessageBox.confirm('確定要刪除?', { center: true });
      const ids = await selection.remove(row);

      //有在畫面上的意思
      const has = itemData.has(row);
      if (has) {
        itemData.remove(row);
        chosen.reset();
      }
      if (itemData.items.length) {
        itemData.updateIdx(ids);
      }
      if (has) {
        blueprint.save();
      }
    };
    const activeClass = (id) => {
      return {
        used: isUsed(id),
        active: isChosen(id),
      };
    };
    const getColor = (type) => {
      return ITEM_TYPE_COLORS[type];
    };

    // onMounted(() => {
    //   var el = document.querySelector('div');
    //   console.log(el.offsetLeft, el.offsetTop);

    //   // selection.fetcher({ exhibId: route.params.id });
    // });

    return {
      scrollbarRef,
      toggleEdit,
      activeClass,
      getColor,
      isChosen,
      dialog,
      onStart,
      toggleSort,

      onSort,
      onDragstart: itemData.onDragstart,
      onRemove,
      onCreate,
      data: selection.data,
      updateTitle,
      isUsed,
      openForm,
      select,
      uploadUrl,
      getThumb,
      exporter,
    };
  },
};
</script>
<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  background: #ebebeb;
}
.used {
  &:after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full bg-white opacity-60 pointer-events-none;
  }
}
.chip-item {
  @apply select-none w-full flex py-2 items-center relative border mb-2 border-gray-200;
  .selected {
    @apply hidden;
  }
  &.active {
    .selected {
      @apply block;
    }
    &:before {
      content: '';
      // @apply absolute top-0 left-0 w-full h-full border-2 border-primary pointer-events-none;
    }
  }
}
</style>
