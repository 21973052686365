<template>
  <div class="flex items-center mt-2">
    <span> <span class="font-bold">水平位移</span> {{ offsetX }}</span>
    <span class="px-4"> |</span>
    <span> <span class="font-bold">垂直位移</span> {{ offsetY }}</span>
  </div>
</template>

<script>
export default {
  props: {
    offsetX: {
      type: Number,
    },
    offsetY: {
      type: Number,
    },
  },
};
</script>
