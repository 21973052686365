<template>
  <div class="relative">
    <div class="flex flex-col cursor-pointer relative">
      <el-image
        :src="$filters.uploadUrl(cover)"
        class="aspect-w-16 aspect-h-10"
        lazy
        fit="cover"
      >
      </el-image>
      <div class="mt-4">
        <h4 class="font-bold text-lg" v-highlight="keyword">{{ title }}</h4>
        <p>登錄號 : {{ serial }}</p>
      </div>
    </div>
    <el-button
      circle
      :loading="loading"
      size="mini"
      @click.prevent.stop="toggle"
      :type="selected ? 'primary' : null"
      class="!absolute top-4 left-4"
      :class="
        !selected
          ? 'hover:bg-transparent hover:border-primary bg-transparent border-2 border-primary w-[28px]'
          : ''
      "
      :icon="selected ? 'el-icon-check' : '.'"
    >
    </el-button>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { ElButton, ElImage } from 'element-plus';
export default {
  components: { ElImage, ElButton },
  props: {
    keyword: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    cover: {
      type: String,
    },
    serial: {
      type: String,
    },
    selected: {
      type: Boolean,
    },

    loading: {
      type: Boolean,
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const { selected } = toRefs(props);
    const toggle = () => {
      // console.log('object');
      emit('toggle', !selected.value);
    };
    return {
      toggle,
      query: {
        author: null,
        keyword: null,
        year: null,
        serial: null,
        category: null,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
:deep .el-icon-loading {
  color: #fff;
}
:deep .el-button.is-loading:before {
  background-color: transparent;
}
</style>
