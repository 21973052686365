import { reactive, ref, watch, inject, toRaw, nextTick } from 'vue';
import Wall from '@/modules/editor/Wall';
import Outer from '@/modules/editor/Outer';
import Door from '@/modules/editor/Door';
import Work from '@/modules/editor/Work';
import Intro from '@/modules/editor/Intro';
import Picture from '@/modules/editor/Picture';
import Grid from '@/modules/editor/Grid';
import Stage from '@/modules/editor/Stage';
import Konva from 'konva';
import {
  GRID,
  STAGE_SIZE,
  ROOM_HEIGHT,
  FLOOR_TEXTURE,
  FLOOR_COLOR,
  ROOM_COLOR,
} from '@/utils/Config';
import { getSnapPos, UUID } from '@/utils/Utils';
import { ElMessage, ElMessageBox } from 'element-plus';
import _, { size } from 'lodash';
import BlueprintService from '@/services/Blueprint';
import { useResizeObserver } from '@vueuse/core';

import items2Blueprint from './Items2Blueprint';

export default ({ id, itemData, chosen, toggleEdit, selection }) => {
  let stage;
  let grid;

  let layer;
  let gridSize = GRID;
  const CLASSES = {
    2: Work,
    3: Work,
    4: Intro,
    5: Picture,
  };

  const loading = ref();
  const canvasRef = ref(null);
  const areaData = reactive({
    key: 'medium',
    width: STAGE_SIZE['medium'].width,
    height: STAGE_SIZE['medium'].height,
  });

  // const sizeKey = ref('medium');

  const initSize = () => {
    if (data.scene?.size) {
      // 新版移除sizeKey,改用scene.sizeKey
      // 如果key存在於config width height 部會使用
      updateArea({
        key: data.scene.size,
        width: data.scene.width,
        height: data.scene.height,
      });
    }
  };

  const updateArea = ({ key, width = 0, height = 0 }) => {
    areaData.key = key;

    if (STAGE_SIZE[key]) {
      areaData.width = STAGE_SIZE[key].width;
      areaData.height = STAGE_SIZE[key].height;
    } else {
      areaData.width = width;
      areaData.height = height;
    }
    updateGrid();

    updateDoor();
  };

  const extra = reactive({
    start: '1',
    depth: ROOM_HEIGHT,
    floor: {
      texture: '',
      color: '',
    },
    outer: {
      exterior: '',
      color: '',
      texture: '',
    },
  });

  const updateExtra = (_extra) => {
    Object.assign(extra, { ..._extra });
  };
  const initExtra = () => {
    let _extra = {};
    // let oldColor = null
    if (data.scene) {
      const _scene = _.cloneDeep(data.scene);
      // oldColor = _scene.color
      Object.keys(_scene).forEach((key) => {
        if (key in extra) {
          _extra[key] = _scene[key];
        }
      });
    }

    _extra.floor.texture = _extra.floor?.texture || '';
    _extra.floor.color = _extra.floor?.color || FLOOR_COLOR;

    _extra.outer.color = _extra.outer?.color || ROOM_COLOR;
    _extra.outer.exterior = _extra.outer?.exterior || ROOM_COLOR;

    updateExtra(_extra);
  };

  const status = ref('walling');
  const data = reactive({});
  const door = ref(null);

  const select = (id, type, scroll) => {
    chosen.update({ id, type, scroll });
  };

  const dbClick = toggleEdit;

  const setStatus = (_status) => {
    if (!stage) return;
    status.value = _status;
    stage.toggleDraggable(_status !== 'walling');
  };

  const toggleStatus = (a, b) => {
    if (status.value === a) {
      setStatus(b);
    } else if (status.value === b) {
      setStatus(a);
    }
  };

  const createWall = (params) => {
    const onDone = () => {
      setStatus('dragging');
    };

    const item = new Wall({
      ...params,
      stage: stage,
      layer: layer,
      onDone,
      onClick: select,
      onDb: dbClick,
    });
    itemData.insert(item);

    if (params.isAction === true) {
      select(item.attrs.id, item.type, false);
    }
  };

  const createDoor = (params) => {
    const item = new Door({
      config: {
        width: GRID * 8,
      },
      stage: stage,
      layer: layer,
    });

    door.value = item;
    updateDoor();
  };

  const updateDoor = () => {
    if (door.value) {
      door.value.x(areaData.width - GRID * 8 - 2 * GRID);
      door.value.y(areaData.height);
    }
  };

  const createItem = (params) => {
    let _params;

    //target 表示拖曳的意思
    if (params.target) {
      if (!itemData.tempItem.value) return;

      setStatus('dragging');
      const e = params;
      e.preventDefault();
      stage.setPointersPositions(e);
      _params = {
        config: {
          ...getSnapPos(stage.getRelativePointerPosition()),
          id: itemData.tempItem.value.id,
          meta: {
            id: itemData.tempItem.value.id,
            idx: itemData.tempItem.value.idx,
            type: itemData.tempItem.value.type,
            l: itemData.tempItem.value.l,
            h: itemData.tempItem.value.h,
            w: itemData.tempItem.value.w,
            // ...itemData.tempItem.value,
          },
        },
        isAction: true,
      };
    } else {
      _params = params;
    }

    const item = new CLASSES[_params.config.meta.type]({
      ..._params,
      stage: stage,
      layer: layer,
      onClick: select,
      onDb: dbClick,
    });

    itemData.insert(item);

    if (params.target) {
      select(item.attrs.id, item.attrs.type, false);
      itemData.resetTempItem();
    }
  };

  const checkItems = () => {
    let errors = '';
    if (!data.items?.length) return;
    data.items.forEach((x) => {
      const idx = selection.findIndex((y) => {
        return y.id === x.id;
      });

      if (idx < 0) {
        errors += `序號${x.meta.idx + 1}-${x.id},`;
      } else {
        console.log('done');
      }
    });
    if (errors) {
      ElMessageBox({
        message: errors + '，元件與清單不符，請刪除物件重新拖曳',
      });
    }
  };

  const initGrid = () => {
    grid = new Grid(areaData.width, areaData.height, gridSize);
    grid.on('mousedown tap', (e) => {
      if (status.value !== 'walling') {
        chosen.reset();
      }
    });
    stage.add(grid);
  };
  let outer;
  const initOuter = () => {
    outer = new Outer(areaData.width, areaData.height, gridSize);
    outer.on('dblclick dbltap', (e) => {
      chosen.update({ type: 'outer' });
      toggleEdit();
    });

    stage.add(outer);
  };
  const updateOuter = () => {
    if (outer) {
      outer.removeChildren();
      outer.set(areaData.width, areaData.height, gridSize);
      outer.render();
    }
  };

  const updateGrid = () => {
    if (grid) {
      grid.removeChildren();
      grid.set(areaData.width, areaData.height, gridSize);
      grid.render();
      updateOuter();
    }
  };

  const initWall = () => {
    stage.on('mousedown', () => {
      if (status.value === 'walling') {
        const _pos = getSnapPos(stage.getRelativePointerPosition());
        let config = {
          ..._pos,
          meta: {
            id: UUID(),
          },
        };
        createWall({
          config,
          isAction: true,
        });
      }
    });
  };

  const initLayer = () => {
    layer = new Konva.Layer();
    stage.add(layer);
  };

  const createStage = (params) => {
    stage = new Stage(params);
    setStatus('dragging');
  };

  const initStage = () => {
    createStage({
      container: 'container',
      width: areaData.width,
      height: areaData.height,
    });
    resizeObserver();
  };

  const fetcher = () => {
    loading.value = true;
    return BlueprintService.find({ id })
      .then((res) => {
        console.log(res);

        if (res.code !== 1) return;

        let _data;

        if (
          res.data.blueprint &&
          res.data.blueprint.constructor.toString().indexOf('Array') < 0
        ) {
          itemData.clearAll();
          _data = JSON.parse(res.data.blueprint);
        } else {
          _data = {
            scene: {},
          };
        }
        if (!_data.scene?.floor) {
          _data.scene.floor = { texture: '', color: '' };
        }
        if (!_data.scene?.outer) {
          _data.scene.outer = { texture: '', color: '', exterior: '' };
        }

        setData(_data);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const loadTemp = (blueprint) => {
    itemData.clearWall();
    setData(blueprint);
    initSize();
    render();
  };

  const setData = (blueprint) => {
    const _data = _.cloneDeep(blueprint);

    // if (!_data.scene.floor) {
    //   _data.scene.floor = { texture: '', color: '' };
    // }
    // if (!_data.scene.outer) {
    //   _data.scene.outer = { texture: '', color: '', exterior: '' };
    // }
    Object.assign(data, _data);

    checkItems();
  };

  const render = () => {
    setStatus('dragging');
    if (!data.scene) return;
    if (data.walls?.length) {
      data.walls.forEach((x) => {
        createWall({
          config: x,
        });
      });
    }
    if (data.items?.length) {
      data.items.forEach((x) => {
        createItem({
          config: x,
        });
      });
      chosen.update({ id: '9999999999' });
      chosen.reset();
    }
    // itemData.unselect();
  };

  const save = () => {
    const data = items2Blueprint({
      version: 'v2',
      items: _.cloneDeep(itemData.items),
      door: door.value,
      areaData,
      // depth: depth.value,
      extra: _.cloneDeep(extra),
      selection,
    });

    loading.value = true;
    if (!data) {
      return Promise.reject();
    }
    return BlueprintService.save({
      id,
      blueprint: JSON.stringify(data),
    }).finally(() => {
      loading.value = false;
    });
  };

  const resizeObserver = () => {
    // 畫面改變大小;
    useResizeObserver(canvasRef, (entries) => {
      const entry = entries[0];
      if (!entry) return;
      const { width, height } = entry.contentRect;

      resizeCanvas(width, height);
    });
  };
  const onDragover = (e) => {
    if (itemData.tempItem.value) {
      e.preventDefault();
    }
  };
  const resizeCanvas = (w, h) => {
    if (!stage) return;
    stage.resize(w, h);
  };

  const init = async () => {
    await fetcher();
    initSize();
    initExtra();
    initStage();
    initGrid();
    initOuter();
    initLayer();
    initWall();
    createDoor();
    render();
  };

  return {
    canvasRef,
    door,
    loading,
    // sizeKey,
    areaData,
    updateArea,

    // depth,
    // updateDepth,

    extra,
    updateExtra,

    setData,
    save,
    status,
    toggleStatus,
    createItem,
    init,
    loadTemp,

    onDragover,
  };
};
