import { reactive, ref } from 'vue';
import SelectionService from '@/services/Selection';

export default (_form) => {
  const form = reactive(_form);

  const loading = ref(false);
  const fetcher = () => {
    loading.value = true;
    return SelectionService.find({ id: form.id })
      .then((res) => {
        if (res.code !== 1) return;
        set(res.data);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const set = (meta) => {
    Object.keys(meta).forEach((key) => {
      if (form[key] !== undefined && meta[key]) {
        form[key] = meta[key];
      }
    });
  };
  const updateOffset = (e) => {
    form.offsetX = Number(e.x.toFixed());
    form.offsetY = Number(e.y.toFixed());
  };

  return { form, loading, updateOffset, set, fetcher };
};
