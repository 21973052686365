<template>
  <El-form label-position="top" :model="form" ref="formRef">
    <El-form-item label="牆面顏色" prop="color" class="text-center">
      <div class="flex">
        <span class="font-bold px-4 border border-gray-500 h-[40px]">
          {{ form.color }}</span
        >
        <El-Color-Picker v-model="form.color" />
        <div>
          <Texture type="wall" v-model="form.texture"></Texture>
        </div>
      </div>
    </El-form-item>
    <p class="text-xs font-base text-gray-400 text-center py-2">
      選取材質將會取代顏色設定。
    </p>
  </El-form>
  <div class="action flex justify-center my-8">
    <El-button @click.prevent="submit" type="primary">儲存設定並關閉</El-button>
  </div>
</template>

<script>
import { reactive, inject } from 'vue';
import Texture from '@/pages/index/components/texture/Index.vue';
import { ElColorPicker, ElButton, ElFormItem, ElForm } from 'element-plus';

export default {
  components: {
    ElColorPicker,
    ElButton,
    ElFormItem,
    ElForm,
    Texture,
  },
  setup() {
    const dialog = inject('dialog');
    const { activeItem } = inject('itemData');
    const form = reactive({
      color: '',
      texture: '',
    });

    Object.keys(activeItem.value.attrs.meta).forEach((key) => {
      if (form[key] !== undefined) {
        form[key] = activeItem.value.attrs.meta[key];
      }
    });

    const submit = () => {
      // meta.color = form.color;
      activeItem.value.attrs.meta = { ...activeItem.value.attrs.meta, ...form };
      activeItem.value.updateColor();
      dialog.toggle();
    };
    return { form, submit };
  },
};
</script>
<style lang="scss" scoped>
:deep(.el-form-item) {
  .el-form-item__label {
    @apply text-center;
  }
  .el-form-item__content {
    @apply inline-flex;
  }
}
</style>
