<template>
  <div class="flex h-full content" v-if="isEditable">
    <div class="selection w-[300px]" v-loading="selectionLoading">
      <Selection />
    </div>
    <div class="blueprint w-0 flex-grow pl-16 flex flex-col">
      <Blueprint v-loading="blueprintLoading"></Blueprint>
      <div class="flex-none pt-8">
        <Editor-Nav />
      </div>
    </div>
    <El-Dialog
      v-bind="dialog.state.attrs"
      v-model="dialog.state.show"
      @close="dialog.toggle()"
    >
      <component :is="dialog.state.component"></component>
    </El-Dialog>
  </div>
</template>
<script>
import { ref, onMounted, provide, inject } from 'vue';
import { ITEM_TYPES } from '@/utils/Config';
//comt
import { ElDialog, ElButton } from 'element-plus';
// import BlueprintControl from './blueprint/Control.vue';
import Blueprint from './blueprint/Index.vue';

import Selection from './selection/Control.vue';

// dialog
import SelectionList from './selection/List.vue';
import workList from './work/List.vue';
import introForm from './form/Intro.vue';
import pictureForm from './form/Picture.vue';
import plainForm from './form/Work.vue';
import solidForm from './form/Work.vue';
import wallForm from './form/Wall.vue';
import outerForm from './form/Outer.vue';
// data
import useDialog from '@/components/useDialog';

import useBlueprint from './useBlueprint';
import useChosen from './useChosen';
import useItems from './useItems';
import EditorNav from './_Nav.vue';
//method

import _ from 'lodash';
import { useRouter } from 'vue-router';
import { ElMessageBox } from 'element-plus';

export default {
  components: {
    Blueprint,

    Selection,
    ElDialog,
    ElButton,
    workList,
    SelectionList,
    wallForm,
    plainForm,
    solidForm,
    introForm,
    pictureForm,
    outerForm,
    EditorNav,
  },

  setup() {
    const exhibId = inject('exhibId');
    const exhibState = inject('exhibState');
    const selection = inject('selection');
    const router = useRouter();

    const chosen = useChosen();
    provide('chosen', chosen);

    const dialog = useDialog();
    provide('dialog', dialog);

    //  儲存canvas 上的物件顯示狀況
    const itemData = useItems({ chosen });
    provide('itemData', itemData);

    const toggleEdit = (params) => {
      if (chosen) {
        const component = `${ITEM_TYPES[chosen.state.type]}Form`;
        dialog.toggle({ component, attrs: { title: '編輯物件' }, ...params });
      }
    };
    provide('toggleEdit', toggleEdit);

    //  konvas blue的資料的啟動建立仔入等
    const blueprint = useBlueprint({
      id: exhibId,
      chosen,
      itemData,
      selection: selection.data,
      toggleEdit,
    });
    provide('blueprint', blueprint);
    provide('saveStep', blueprint.save);

    onMounted(() => {
      if (exhibState.isEditable) {
        blueprint.init();
      } else {
        ElMessageBox.alert('審核中或通過審核之展覽不可以編輯。', {
          center: true,
          showClose: false,
        }).finally(() => {
          return router.push({ name: 'Home' });
        });
      }
    });

    return {
      dialog,
      isEditable: exhibState.isEditable,
      selectionLoading: selection.loading,
      blueprintLoading: blueprint.loading,
      createItem: blueprint.createItem,

      remove: itemData.remove,

      chosen,
      toggleEdit,
    };
  },
};
</script>
