<template>
  <div class="">
    <div class="mb-2 font-bold whitespace-nowrap h-[40px] flex items-center">
      <slot name="title"></slot>
      <slot name="btn"> </slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
