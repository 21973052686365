import { reactive } from 'vue';

export default () => {
  const state = reactive({
    id: '',
    type: '',
    scroll: false,
  });

  const update = ({ id, type, scroll }) => {
    state.id = id;
    state.type = type;
    state.scroll = scroll;
  };
  const reset = () => {
    state.id = null;
    state.type = null;
    state.scroll = false;
  };

  return {
    reset,
    state,
    update,
  };
};
