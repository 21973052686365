import { STAGE_SIZE } from '@/utils/Config';
export default ({
  version,
  items,
  color,
  areaData,
  depth,
  selection,
  door,
  extra,
}) => {
  const data = {
    version,
    scene: {
      size: areaData.key,
      width: areaData.width,
      height: areaData.height,
      color,
      depth: depth,
      ...extra,
    },

    walls: items
      .filter((x) => x.attrs.meta.type === 1)
      .map((y) => {
        const attrs = y.attrs;

        return {
          id: attrs.meta.id,
          x: attrs.x,
          y: attrs.y,
          width: attrs.width,
          height: attrs.height,
          meta: {
            ...attrs.meta,
          },
        };
      }),
    items: items
      .filter((x) => x.attrs.meta.type !== 1)
      .map((y) => {
        const attrs = y.attrs;

        return {
          id: attrs.meta.id,
          x: attrs.x,
          y: attrs.y,
          width: attrs.width,
          height: attrs.height,
          rotation: attrs.rotation,
          meta: {
            ...attrs.meta,
            // ...meta,
          },
        };
      })
      .sort(function (a, b) {
        return a.meta.idx - b.meta.idx;
      }),
  };

  return data;
};
