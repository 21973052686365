<template>
  <div class="flex flex-wrap lg:-mx-8 min-h-[200px]">
    <div class="py-20 w-full text-center" v-if="!rows.length">目前沒有結果</div>
    <div
      v-for="row in rows"
      :key="row.id"
      class="w-full lg:w-3/12 lg:px-8 mb-16"
    >
      <slot name="item" :row="row">
        <img :src="row.cover" alt="" />
        <div class="mt-4">
          <h4 class="font-bold text-lg">
            {{ row.title }}
          </h4>
          <p class="block">
            {{ row.serial }}
          </p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Array,
    },
  },

  setup(props) {
    return {};
  },
};
</script>
