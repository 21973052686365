<template>
  <div>
    <div class="flex flex-col h-full" v-loading.lock="loading">
      <Filter class="mb-10" />

      <List :rows="rows">
        <template v-slot:item="slot">
          <Card
            :keyword="query?.keyword"
            :loading="setLoading(slot.row.id)"
            :selected="setSelected(slot.row.id)"
            @toggle="(e) => onToggle({ row: slot.row, selected: e })"
            :title="slot.row.title"
            :cover="slot.row.cover"
            :serial="slot.row.serial"
          ></Card>
        </template>
      </List>
      <Pagination></Pagination>
    </div>
  </div>
</template>

<script>
import { inject, ref, provide } from 'vue';
// import List from '@/pages/index/components/list/Index.vue';
import WorkService from '@/services/Work';
import Card from './Card.vue';
import { ElMessageBox } from 'element-plus';
import useList from '@/pages/index/components/list/useList';

import useFilter from '@/pages/index/components/list/useFilter';
import List from '@/pages/index/components/list/List.vue';
import Filter from '@/pages/index/components/list/Filter.vue';
import Pagination from '@/pages/index/components/list/Pagination.vue';
import { WORK_DEFAULT_L } from '@/utils/Config';
import { uploadUrl } from '@/utils/Utils';
export default {
  components: { Card, List, Filter, Pagination },
  setup() {
    const selection = inject('selection');
    const itemData = inject('itemData');
    const chosen = inject('chosen');
    const blueprint = inject('blueprint');
    const { query, opts } = useFilter({
      fields: {
        creator: null,
        keyword: null,
        year: null,
        serial: null,
        category: null,
      },
    });

    const { data, loading, update, pagination } = useList({
      service: WorkService,
    });

    provide('filter', {
      query,
      opts,
    });

    provide('list', {
      loading,
      update,
      pagination,
    });

    update();

    const activeId = ref(null);
    const onToggle = async ({ row, selected }) => {
      activeId.value = row.id;
      if (!selected) {
        await ElMessageBox.confirm('輸入資料將會同步刪除，確定要移除選品?', {
          center: true,
        });
        // 這裡的row  應該要是chip
        const idx = selection.data.findIndex((y) => y.work_id === row.id);

        if (idx > -1) {
          const chip = selection.data[idx];
          itemData.remove(chip);
          chosen.reset();
          await selection.remove(chip);
          await blueprint.save();
        }
      } else {
        // row.work.id
        const insert = (_row) => {
          selection.insert({
            ...row,
            work_id: row.id,
            ..._row,
          });
        };

        if (!row.l || !row.h) {
          const img = new Image();
          img.src = uploadUrl(row.cover);
          img.onload = () => {
            insert({
              l: row.l || WORK_DEFAULT_L,
              h: row.h || Math.round(WORK_DEFAULT_L * (img.height / img.width)),
            });
          };
        } else {
          insert();
        }
      }
      activeId.value = null;
    };
    const setSelected = (id) => {
      return selection.data.some((y) => y.work_id == id);
    };
    const setLoading = (id) => {
      return selection.loading.value && activeId.value == id;
    };
    return {
      rows: data,
      loading,
      query,
      setSelected,
      setLoading,
      onToggle,
    };
  },
};
</script>
