import Konva from 'konva';
export default class Layer extends Konva.Layer {
  constructor(width, height, size) {
    super();
    this.set(width, height, size);
    this.render();
    this.bind();
  }
  set(width, height, size) {
    this.h = height;
    this.w = width;
    this.size = size;
    new Konva.Rect({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: '#d9b562',
      // stroke: '#ddd',
      // strokeWidth: 1,
      shadowColor: 'black',
      shadowBlur: 2,
      shadowOffset: {
        x: 1,
        y: 1,
      },
      shadowOpacity: 0.4,
      draggable: true,
    });
  }
  bind() { }
  render() {
    for (let i = 0; i <= this.w / this.size; i++) {
      this.add(
        new Konva.Line({
          points: [
            Math.round(i * this.size) + 0.5,
            0,
            Math.round(i * this.size) + 0.5,
            this.h,
          ],
          stroke: '#ddd',
          strokeWidth: 1,
        })
      );
    }

    this.add(
      new Konva.Line({
        points: [0, 0, 10, 10],
      })
    );
    for (let j = 0; j <= this.h / this.size; j++) {
      this.add(
        new Konva.Line({
          points: [
            0,
            Math.round(j * this.size),
            this.w,
            Math.round(j * this.size),
          ],
          stroke: '#000',
          strokeWidth: 0.5,
        })
      );
    }
    // this.add(
    //   new Konva.Rect({
    //     x: -this.size / 2,
    //     y: -this.size / 2,
    //     width: this.w + this.size,
    //     height: this.h + this.size,
    //     stroke: '#ddd',
    //     strokeWidth: this.size,
    //   })
    // );
  }
}
