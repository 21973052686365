<template>
  <div class="flex items-center">
    <!-- <span class="font-bold mx-4"> {{ color }}</span> -->
    <El-Color-Picker v-model="color" />
    <Texture type="floor" class="w-[10rem]" v-model="texture"></Texture>
  </div>
</template>
<script>
import { computed } from 'vue';
import Texture from '@/pages/index/components/texture/Index.vue';
import { ElColorPicker } from 'element-plus';
export default {
  components: { Texture, ElColorPicker },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const update = (val) => {
      emit('update:modelValue', { floor: { ...props.modelValue, ...val } });
    };
    const texture = computed({
      get() {
        return props.modelValue.texture;
      },
      set(val) {
        update({ texture: val });
        // emit('update:modelValue', { floor: { texture: val } });
      },
    });
    const color = computed({
      get() {
        return props.modelValue.color;
      },
      set(val) {
        update({ color: val });
        // emit('update:modelValue', { floor: { color: val ,texture: } });
      },
    });

    return { texture, color };
  },
};
</script>
