<template>
  <div class="flex" v-loading="loading">
    <div class="w-[600px]">
      <h3 class="font-bold text-primary mb-2">位置預覽</h3>
      <div
        class="flex items-center justify-center bg-gray-100 min-h-[600px]"
        v-loading="converting"
      >
        <Pos-Canvas
          v-if="imgObj"
          :type="'picture'"
          :l="form.l"
          :h="form.h"
          :cover="imgObj"
          :offset-x="form.offsetX"
          :offset-y="form.offsetY"
          :maxheight="extra.depth"
          @update="updateOffset"
        />
      </div>
      <Offset :offset-x="form.offsetX" :offset-y="form.offsetY"></Offset>
    </div>

    <div class="flex-grow ml-8">
      <h3 class="font-bold text-primary mb-2">內容</h3>
      <El-form label-position="top" :model="form" ref="formRef" :rules="rules">
        <El-form-item label="上傳圖片">
          <template #label>
            上傳圖片
            <small class="helper"
              >格式限JPG/PNG，檔案需小於10mb，最大寬度不超過1440px。</small
            >
          </template>
          <Uploader
            :type="'image'"
            v-model:url="form.pic"
            @uploaded="onUploaded"
          ></Uploader>
        </El-form-item>
        <El-form-item label="圖片寬度" prop="l">
          <El-Slider
            :step="30"
            :min="30"
            :max="900"
            v-model="form.l"
          ></El-Slider>
        </El-form-item>
        <El-form-item label="圖片標題" prop="title">
          <El-Input v-model="form.title" :debounce="1000" />
        </El-form-item>
        <El-form-item label="文字內文" prop="excerpt">
          <El-Input type="textarea" :rows="6" v-model.debounce="form.excerpt" />
        </El-form-item>
        <El-form-item prop="excerpt" label="展品外框">
          <Frame
            type="base"
            class="ml-2 h-[42px]"
            v-model="form.extra.frame"
          ></Frame>
        </El-form-item>
        <El-form-item label="影片嵌入網址" prop="videouri">
          <El-Input v-model="form.videouri" />
        </El-form-item>
        <!-- <El-form-item
          v-if="form.videouri.startsWith('//imedia')"
          label="影片外連網址"
          prop="link2"
        >
          <El-Input v-model="form.link2" />
        </El-form-item> -->
        <El-form-item>
          <template #label>
            上傳影片
            <small class="helper">格式限Mp4，檔案需小於60mb。</small>
          </template>
          <Uploader
            :type="'video'"
            :limit="60"
            :accept="'video/mp4,video/x-m4v,video/*'"
            v-model:url="form.mp4"
            :action="uploadVideoAction"
          ></Uploader>
        </El-form-item>
        <El-form-item v-if="form.mp4">
          <El-radio-group v-model="form.blank">
            <El-radio :label="'No'">直接播放</El-radio>
            <El-radio :label="'Yes'">另開視窗播放</El-radio>
          </El-radio-group>
        </El-form-item>
        <El-form-item label="是否為外館展品">
          <div class="flex flex-col">
            <El-checkbox
              v-model="form.others"
              :true-label="'Yes'"
              :false-label="'No'"
              @change="agree = false"
              >是，此新增物件為外館展品</El-checkbox
            >
            <El-checkbox v-if="form.others === 'Yes'" v-model="agree">
              我已確定取得此外館展品使用授權
            </El-checkbox>
          </div>
        </El-form-item>
      </El-form>
    </div>
  </div>
  <div class="action flex justify-center my-8">
    <El-button
      :disabled="form.others === 'Yes' && !agree"
      @click.prevent="submit"
      type="primary"
      :loading="loading"
      >儲存設定並關閉</El-button
    >
  </div>
</template>

<script>
import { inject, ref, computed, watch } from 'vue';
import PosCanvas from './_Canvas.vue';
import Offset from './_Offest.vue';
import useForm from './useForm';
import Frame from '@/pages/index/components/frame/Index.vue';
import { uploadUrl } from '@/utils/Utils';
// import { FRAME_WIDTH } from '@/utils/Config.js';
import {
  ElButton,
  ElFormItem,
  ElForm,
  ElInput,
  ElSlider,
  ElRadioGroup,
  ElRadio,
  ElCheckbox,
} from 'element-plus';
import Uploader from '@/pages/index/components/Uploader.vue';
export default {
  components: {
    ElButton,
    ElFormItem,
    ElForm,
    ElInput,
    PosCanvas,
    ElSlider,
    Uploader,
    Offset,
    ElRadioGroup,
    ElRadio,

    ElCheckbox,
    Frame,
  },
  setup() {
    const agree = ref(false);

    const dialog = inject('dialog');
    const { activeItem } = inject('itemData');
    const chosen = inject('chosen');
    const selection = inject('selection');

    const { extra } = inject('blueprint');

    const rules = {
      title: [
        {
          required: true,
          message: '請輸入標題',
          trigger: 'blur',
        },
        {
          max: 100,
          message: '字數需小於100',
          trigger: 'blur',
        },
      ],
    };
    const formRef = ref();
    const converting = ref(false);
    const loading = computed(() => {
      return formLoading.value || selection.loading.value;
    });
    const imgObj = ref(null);
    const {
      form,
      updateOffset,
      fetcher,
      loading: formLoading,
    } = useForm({
      id: null,
      l: 240,
      w: 30,
      h: 0,
      offsetX: 0,
      offsetY: 0,
      pic: null,
      title: '預設標題',
      type: 5,
      excerpt: '',
      videouri: '',
      link2: '',
      mp4: '',
      others: 'No',
      blank: 'No',
      extra: {
        frame: '',
      },
    });

    // 如果有選擇狀態 要把已經有的狀態帶入;
    const convertImg = (url) => {
      if (!url) return;
      converting.value = true;
      const img = new Image();
      img.src = uploadUrl(url);
      img.onload = () => {
        form.h = (img.height / img.width) * form.l;
        converting.value = false;
        imgObj.value = img;
      };
    };

    if (chosen.state.id) {
      form.id = chosen.state.id;
      fetcher().then(() => {
        convertImg(form.pic);
        if (form.others === 'Yes') {
          agree.value = true;
        }
      });
    }

    const onUploaded = (_imgObj) => {
      imgObj.value = _imgObj;
      form.h = (imgObj.value.height / imgObj.value.width) * form.l;
    };
    watch(
      form,
      (val, old) => {
        if (imgObj.value && val.l) {
          form.h = (imgObj.value.height / imgObj.value.width) * val.l;
        }
      },
      {
        deep: true,
      }
    );
    const validateYoutube = (rule, value, callback) => {
      const re = new RegExp('^https?://youtu.be/', 'i');
      if (!value || re.test(value)) {
        callback();
      } else {
        callback(new Error('格式錯誤'));
      }
    };
    const uploadVideoAction = ref(
      process.env.VUE_APP_API_URL + '/chip/upload_mp4'
    );

    const submit = () => {
      let isValid = true;
      formRef.value.validate((valid) => {
        isValid = valid;
      });
      if (!isValid) return;

      if (form.id) {
        selection
          .update({
            ...form,
            cover: form.pic,
            extra: JSON.stringify(form.extra),
          })
          .then(() => {
            if (activeItem.value) {
              activeItem.value.updateFrame(!!form.extra.frame);
              activeItem.value.updateWidth(
                // form.l + (form.extra.frame ? FRAME_WIDTH * 2 : 0)
                form.l
              );

              activeItem.value.updateTextPos();
            }
            onSubmitted();
          })
          .finally(() => {});
      } else {
        form.cover = form.pic;
        selection
          .insert({ ...form, extra: JSON.stringify(form.extra) })
          .then(() => {
            dialog.toggle();
            onSubmitted();
          })
          .finally(() => {});
      }
    };
    const onSubmitted = () => {
      dialog.toggle();
    };

    return {
      validateYoutube,
      form,
      formRef,
      updateOffset,
      submit,
      rules,
      loading,
      imgObj,
      converting,
      onUploaded,
      extra,
      uploadVideoAction,
      agree,
    };
  },
};
</script>
