<template>
  <div class="w-full">
    <div
      v-loading="loading || fontLoading"
      id="posCanvas"
      class="bg-gray-100"
    ></div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, watch, computed } from 'vue';
import Konva from 'konva';
import { ROLE_Z, CANVAS_LIMIT } from '@/utils/Config';
import _ from 'lodash';

export default {
  props: {
    l: {
      type: Number,
    },
    h: {
      type: Number,
    },
    cover: {
      type: Object,
    },
    excerpt: {
      type: String,
    },
    title: {
      type: String,
    },
    color: {
      type: String,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    offsetY: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
    },
    extra: {
      type: Object,
    },
    maxheight: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update'],

  setup(props, { emit }) {
    const {
      l,
      h,
      offsetY,
      offsetX,
      title,
      excerpt,
      cover,
      color,
      type,
      maxheight,
      extra,
    } = toRefs(props);
    // const uploadUrl = ref(process.env.VUE_APP_UPLOAD_URL + '/');

    let stage = {};
    let layer;
    let intro;

    let baseLine;

    let state = reactive({
      canvasW: l.value + 120,
      canvasH: maxheight.value,
      posX: computed(() => (state.canvasW - l.value) / 2),
      posY: computed(() => maxheight.value - ROLE_Z - h.value / 2),
    });
    const loading = ref(false);

    // const dragBoundFunc = (box) => {
    //   box.dragBoundFunc(function (pos) {
    //     return {
    //       x: Math.max(0, Math.min(pos.x, state.canvasW - l.value)),
    //       y: Math.max(0, Math.min(pos.y, state.canvasH - h.value)),
    //     };
    //   });
    // };

    const bind = (box) => {
      box.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
      });
      box.on('mouseout', function () {
        document.body.style.cursor = 'default';
      });
      box.on('dragend', function (e) {
        // console.log(e.target.attrs.name);
        emit('update', {
          x: e.target.attrs.x - state.posX,
          y: e.target.attrs.y - state.posY,
        });
      });
    };
    // const reinitDebounce = useDebounceFn(() => {
    //   reinit();
    // }, 500);

    // const reinit = () => {
    //   stage.removeChildren();
    //   init();
    // };
    let titleBox;
    let excerptBox;
    let picture;

    const setStage = (_stage) => {
      let scale =
        state.canvasW > state.canvasH
          ? CANVAS_LIMIT / state.canvasW
          : CANVAS_LIMIT / state.canvasH;
      _stage.setAttr('width', state.canvasW * scale);
      _stage.setAttr('height', state.canvasH * scale);
      _stage.scale({ x: scale, y: scale });
    };
    const fontLoading = ref(false);
    const init = () => {
      // stage
      stage = new Konva.Stage({
        container: 'posCanvas',
        // width: state.canvasW * scale,
        // height: state.canvasH * scale,
      });
      // stage.scale({ x: scale, y: scale });
      setStage(stage);

      // layer
      layer = new Konva.Layer();
      stage.add(layer);
      // baseline
      baseLine = new Konva.Line({
        points: [
          0,
          maxheight.value - ROLE_Z,
          state.canvasW,
          maxheight.value - ROLE_Z,
        ],
        stroke: '#cccccc',
        strokeWidth: 1,
        lineJoin: 'round',
        dash: [10, 5],
      });
      layer.add(baseLine);

      let pos = {
        x: state.posX + offsetX.value,
        y: state.posY + offsetY.value,
      };

      // intro

      if (type.value === 'intro') {
        intro = new Konva.Group({
          name: 'box',
          draggable: true,
          ...pos,
          width: l.value,
          height: h.value,
        });

        titleBox = new Konva.Text({
          extra: { y: 0 },
          x: 0,
          y: 0,
          width: l.value,
          text: title.value,
          fontSize: extra.value.title.size || 24,
          align: extra.value.title.align || 'left',
          strokeWidth: extra.value.title.bold ? 1 : 0,
          fontFamily: extra.value.title.family || 'Noto Sans TC',
          // fontFamily: 'Noto Serif TC',
          stroke: extra.value.title.color || '#333',
          fill: extra.value.title.color || '#333',
        });

        excerptBox = new Konva.Text({
          name: 'excerpt',
          extra: { y: 50 },
          x: 0,
          y: 50 + (extra.value.excerpt.offsetY || 0),
          width: l.value,
          lineHeight: 2,
          text: excerpt.value,
          fontSize: extra.value.excerpt.size || 16,
          align: extra.value.excerpt.align || 'left',
          strokeWidth: extra.value.excerpt.bold ? 1 : 0,
          fontFamily: extra.value.excerpt.family || 'Noto Sans TC',
          stroke: extra.value.excerpt.color || '#333',
          fill: extra.value.excerpt.color || '#333',
        });
        intro.add(titleBox);
        intro.add(excerptBox);

        bind(intro);
        // dragBoundFunc(intro);
        layer.add(intro);

        watch([title, excerpt], ([_title, _excerpt]) => {
          titleBox.text(_title);
          excerptBox.text(_excerpt);
          layer.draw();
        });

        const updateIntro = (data, item) => {
          item.setAttr('fontSize', data.size);
          item.setAttr('align', data.align);
          item.setAttr('strokeWidth', data.bold ? 1 : 0);
          item.setAttr('stroke', data.color);
          item.setAttr('fill', data.color);
          item.setAttr('fontFamily', data.family);
          item.setAttr('y', item.attrs.extra?.y + (data.offsetY || 0));
          layer.batchDraw();
          updateFont(data, item);
        };

        const updateFont = (data, item) => {
          if ('fonts' in document) {
            fontLoading.value = true;
            Promise.all([document.fonts.load('1em ' + data.family)]).then(
              () => {
                item.setAttr('fontFamily', data.family);
                layer.batchDraw();
                fontLoading.value = false;
              }
            );
          }
        };

        watch(
          () => _.cloneDeep(extra.value.title),
          (val, old) => {
            updateIntro(val, titleBox);
          },
          { deep: true, immediate: true }
        );
        watch(
          () => _.cloneDeep(extra.value.excerpt),
          (val, old) => {
            updateIntro(val, excerptBox);
          },
          { deep: true, immediate: true }
        );
      }

      watch([l], ([val]) => {
        // 改變stage寬高
        state.canvasW = val + 120;
        // stage.setAttr('width', state.canvasW);
        setStage(stage);

        if (type.value === 'intro') {
          titleBox.setAttr('width', val);
          excerptBox.setAttr('width', val);
        } else if (type.value === 'picture') {
          picture.setAttr(
            'height',
            (cover.value.height / cover.value.width) * val
          );

          picture.setAttr('width', val);
          picture.setAttr('y', pos.y);
          picture.setAttr('X', pos.x);
        }
        console.log(val);
        baseLine.setAttr('points', [
          0,
          maxheight.value - ROLE_Z,
          state.canvasW,
          maxheight.value - ROLE_Z,
        ]);

        // reinitDebounce();
      });
      if (type.value === 'picture' || type.value === 'work') {
        watch(
          cover,
          (val) => {
            // 第一次灌入
            if (!picture) {
              if (!val) {
                loading.value = false;
                return;
              }

              picture = new Konva.Image({
                name: 'box',
                draggable: true,

                ...pos,
                width: l.value,
                height: h.value,
                image: cover.value,
              });

              bind(picture);
              // dragBoundFunc(picture);
              layer.add(picture);
            } else {
              if (!val) {
                picture.destroy();
                picture = null;
                return;
              }

              picture.setAttrs({
                image: cover.value,
                width: l.value,
                height: h.value,
              });
              layer.batchDraw();
            }
          },
          {
            immediate: true,
          }
        );
      }
    };

    onMounted(() => {
      init();
    });
    const getIntro = () => {
      return intro;
    };
    return { loading, getIntro, fontLoading };
  },
};
</script>
<style lang="scss" scoped>
// /deep/ canvas,

:deep(.konvajs-content) {
  @apply border border-gray-500 bg-white mx-auto;
}
</style>
