import { reactive, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useRoute } from 'vue-router';
export default ({ query, service, url }) => {
  const rows = reactive([]);
  let loading = ref(false);

  let queryCondition = reactive({ ...query });

  const update = (params) => {
    if (params?.page) {
      pagination.page = params.page;
    }

    Object.assign(queryCondition, params);
    return fetcher();
  };
  const route = useRoute();

  const pagination = reactive({
    page: 1,
    limit: 12,
    total: 0,
    update: (page) => {
      pagination.page = page;

      fetcher();
    },
  });

  const create = (params) => {
    loading.value = true;
    service
      .create({
        ...params,
      })
      .then((res) => {
        if (res.code !== 1) {
          ElMessage({ message: '操作錯誤.', type: 'error' });
          return;
        }
        rows.unshift(res.data);
        loading.value = false;
      });
  };
  const del = (params) => {
    ElMessageBox.confirm('確定要刪除?', { center: true })
      .then(() => {
        loading.value = true;
        service
          .del({
            ...params,
          })
          .then((res) => {
            if (res.code !== 1) {
              ElMessage({ message: '操作錯誤.', type: 'error' });
              return;
            }
            const idx = rows.findIndex((x) => {
              return x.id === params.id;
            });

            if (idx > -1) {
              rows.splice(idx, 1);
            }
          })
          .finally(() => {
            loading.value = false;
          });
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const fetcher = () => {
    loading.value = true;

    return service[url || 'list']({
      ...queryCondition,
      page: pagination.page,
      limit: pagination.limit,
    })
      .then((res) => {
        if (res.code !== 1) {
          ElMessage({ message: '操作錯誤.', type: 'error' });
          return;
        }
        const _res = res.data ? res.data : res;
        if (!_res.subset) return;
        rows.length = 0;

        rows.push(..._res.subset);

        pagination.total = _res.total;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    create,
    del,
    update,
    pagination,

    data: rows,
    loading,
  };
};
