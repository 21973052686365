import Konva from 'konva';
export default class Layer extends Konva.Layer {
  constructor(width, height, size) {
    super();
    this.set(width, height, size);
    this.render();
    this.bind();
  }
  set(width, height, size) {
    this.h = height;
    this.w = width;
    this.size = size;
  }
  bind() { }
  render() {
    this.add(
      new Konva.Rect({
        fillEnabled: false,
        x: -this.size / 2,
        y: -this.size / 2,
        width: this.w + this.size,
        height: this.h + this.size,
        stroke: '#cccccc',
        strokeWidth: this.size,
      })
    );
  }
}
