<template>
  <div class="h-full" v-loading.lock="loading">
    <div class="flex justify-center">
      <Filter />
    </div>

    <div class="mt-8" v-if="newList.length">
      <El-Collapse v-model="active" :accordion="true">
        <El-Collapse-Item v-for="row in newList" :key="row.id" :name="row.id">
          <template #title>
            <div class="title leading-5">
              <h4 class="font-bold text-lg">
                {{ row.title }}
              </h4>
              <p>
                {{ row.author }}
              </p>
            </div>
            <div
              class="actions ml-auto mr-1 flex"
              v-if="active.indexOf(row.id) > -1"
            >
              <div class="mr-2">
                <a href="#" class="link" @click.stop="all(row.id)">全選</a> /
                <a href="#" class="link" @click.stop="clear">取消</a>
              </div>
              <El-Button class="xl" @click.stop.prevent="onImport(row.id)">
                匯入展覽選品
              </El-Button>
            </div>
          </template>
          <div class="ml-8">
            <div class="flex flex-wrap -mx-2">
              <div
                v-for="(chip, index) in exhibList[row.id]"
                :key="chip.id"
                class="w-4/12 p-4 cursor-pointer"
                @click.prevent="toggleChip(chip)"
              >
                <div class="flex items-center">
                  <p
                    class="
                      mr-6
                      rounded-full
                      text-center
                      w-[20px]
                      h-[20px]
                      leading-[20px]
                    "
                    :class="
                      chips.indexOf(chip.id) > -1
                        ? 'bg-primary border-primary text-white '
                        : ''
                    "
                  >
                    {{ index + 1 }}
                  </p>
                  <div v-if="chip.type !== 4" class="w-24 h-16 relative">
                    <img
                      class="w-full h-full object-cover"
                      :src="$filters.uploadUrl(chip.cover || chip.pic, 'sm')"
                      :alt="chip.title"
                    />
                    <div
                      class="
                        absolute
                        top-0
                        right-0
                        bg-primary
                        rounded-md
                        text-center
                        w-6
                        h-6
                        leading-[1.2rem]
                        text-sm text-white
                        scale-75
                      "
                      v-if="chip.others === 'Yes'"
                    >
                      外
                    </div>
                  </div>
                  <p class="ml-4 leading-snug">
                    {{ chip.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </El-Collapse-Item>
      </El-Collapse>
    </div>
    <h2 class="text-center text-xl font-bold py-20" v-else>目前沒有資料</h2>
    <Pagination v-if="rows.length" />
  </div>
</template>

<script>
import { ref, provide, inject, watch, reactive, computed } from 'vue';
import { getThumb } from '@/utils/Utils';

import useList from '@/pages/index/components/list/useList';
import useFilter from '@/pages/index/components/list/useFilter';
import Pagination from '@/pages/index/components/list/Pagination.vue';
import Filter from '@/pages/index/components/list/Filter.vue';
import {
  ElButton,
  ElCollapse,
  ElCollapseItem,
  ElMessageBox,
  // ElCheckbox,
} from 'element-plus';
import ExhibService from '@/services/Exhib';
import SelectionService from '@/services/Selection';

export default {
  components: {
    Filter,
    Pagination,
    ElButton,
    // ElCheckbox,
    ElCollapse,
    ElCollapseItem,
  },
  setup() {
    let active = ref([]);
    const exhibId = inject('exhibId');

    const { query, opts } = useFilter({
      fields: {
        keyword: null,
        start_ts: null,
        end_ts: null,
      },
    });

    const { data, loading, update, pagination } = useList({
      service: ExhibService,
      url: 'mine',
    });

    provide('filter', {
      query,
      opts,
    });

    provide('list', {
      loading,
      update,
      pagination,
    });
    // const removeSelf = () => {
    //   const idx = data.findIndex((x) => x.id === exhibId);
    //   if (idx < 0) return;
    //   data.splice(idx, 1);
    // };
    update();
    // .then(() => {
    //   removeSelf();
    // });

    const newList = computed(() => {
      if (!data || !data.length) return [];

      const _data = data.filter((x) => {
        return x.id !== exhibId && x.status !== 'Invalid';
      });

      return _data;
    });

    const selection = inject('selection');
    const dialog = inject('dialog');
    const exhibList = reactive({});
    const chips = reactive([]);
    const toggleChip = (chip) => {
      if (chip.others === 'Yes') {
        return;
      }

      const idx = chips.indexOf(chip.id);
      if (idx > -1) {
        chips.splice(idx, 1);
      } else {
        chips.push(chip.id);
      }
    };
    const all = (id) => {
      chips.length = 0;
      const ids = exhibList[id].map((x) => x.id);
      if (ids.length) {
        chips.push(...ids);
      }
    };
    const clear = () => {
      chips.length = 0;
    };

    const onImport = async (id) => {
      await ElMessageBox.confirm(
        '匯入其它展覽展品清單將會取代目前編輯中的清單，<br>確定要匯入並取代嗎？?',
        '注意',
        { center: true, dangerouslyUseHTMLString: true }
      );

      selection.clone({
        fromId: id,
        items: exhibList[id],
        chips: chips.length ? chips : null,
      });
      dialog.toggle();
    };

    watch(active, (val) => {
      if (!val) return;
      // 換類別的時候 要清除chips
      clear();
      if (!exhibList[val]) {
        SelectionService.list({ id: val }).then((res) => {
          if (res.code !== 1 || !res.data.items) return;
          exhibList[val] = [];
          exhibList[val].push(...res.data.items);
        });
      }
    });

    return {
      newList,
      active,
      getThumb,
      onImport,
      rows: data,
      loading,
      exhibList,
      insert: selection.insert,
      toggleChip,
      chips,
      all,
      clear,
    };
  },
};
</script>
<style lang="scss" scoped>
:deep .el-collapse-item {
  .el-collapse-item__header {
    @apply h-auto py-4;

    > .el-collapse-item__arrow {
      order: 1;
      @apply text-gray-900 ml-0 mr-4;
      font-size: 1.5rem;
    }
    > .title {
      order: 2;
    }
    > .actions {
      order: 3;
    }
  }
}
</style>
