<template>
  <div class="flex">
    <select
      class="form-select !w-[140px] !py-1 text-sm"
      v-model="form.key"
      @change="update"
    >
      <option v-for="(size, key) in opts" :key="key" :value="key">
        {{ size.name }}
      </option>
    </select>
    <div class="flex pl-2 items-center" v-show="form.key === 'custom'">
      <el-input
        class="w-20"
        v-model="form.width"
        type="number"
        placeholder="寬"
        @input="update"
        min="1"
      >
        <template #suffix>
          <span class="select-none text-xs pr-2"> 格</span>
        </template>
      </el-input>
      <el-input
        class="w-20 ml-2 text-center"
        v-model="form.height"
        type="number"
        placeholder="寬"
        @input="update"
        min="1"
      >
        <template #suffix>
          <span class="select-none text-xs pr-2"> 格</span>
        </template>
      </el-input>
    </div>
  </div>
</template>
<script>
import { STAGE_SIZE, GRID } from '@/utils/Config';

import { reactive, toRefs, watch } from 'vue';
import { ElInput } from 'element-plus';
import _ from 'lodash';
export default {
  components: { ElInput },
  props: {
    size: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const opts = reactive({
      ...STAGE_SIZE,
      custom: {
        name: '自訂',
      },
    });
    const { size } = toRefs(props);
    const form = reactive({
      key: null,
      width: 0,
      height: 0,
    });
    watch(
      size,
      (val, old) => {
        if (!val) return;
        Object.assign(form, {
          key: val.key,
          width: val.width / GRID,
          height: val.height / GRID,
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const update = () => {
      emit('update', {
        key: form.key,
        width: form.width * GRID,
        height: form.height * GRID,
      });
    };

    return { form, opts, update };
  },
};
</script>
<style lang=""></style>
