import { ref, reactive, toRefs, computed, watch } from 'vue';
import _ from 'lodash';
export default ({ chosen }) => {
  let items = reactive([]);
  let tempItem = ref();

  let activeItem = computed({
    get() {
      const item = items.find((x) => x.attrs.id === chosen.state.id) || null;
      return item;
    },
  });
  watch(chosen.state, (val) => {
    if (val.id) {
      updateSelect();
    } else {
      unselect();
    }
  });
  const insert = (row) => {
    items.push(row);
  };

  const has = (row) => {
    const _id = row.id || chosen.state.id;
    const idx = items.findIndex((x) => x.attrs.id === _id);
    return idx > -1;
  };

  const remove = (row) => {
    const _id = row.id || chosen.state.id;
    const idx = items.findIndex((x) => x.attrs.id === _id);
    if (idx > -1) {
      items[idx].kill();
      items.splice(idx, 1);
    }
  };

  const resetActiveItem = () => {
    console.log('reset  todo');
  };

  const unselect = (id) => {
    if (!id) {
      items.forEach((x) => {
        x.toggleActive(false);
      });
    }
  };

  const updateSelect = () => {
    unselect();
    if (activeItem.value) {
      activeItem.value.toggleActive();

      if (activeItem.value && activeItem.value.attrs.meta.type !== 1) {
        activeItem.value.updateRotateAnchorOffset();
      }
    }
  };

  const clearWall = () => {
    const _items = [];
    items.forEach((x) => {
      if (x.attrs.meta.type == 1) {
        x.kill();
      } else {
        _items.push(x);
      }
    });
    items.splice(0, items.length, ..._items);
  };

  // const clearItems = () => {
  //   items.forEach((x) => {
  //     if (x.attrs.meta.type > 1) {
  //       x.kill();
  //     }
  //   });

  //   items = items.filter((x) => x.attrs.meta.type === 1);
  // };

  const clearAll = () => {
    items.forEach((x) => {
      x.kill();
    });

    items.length = 0;
    // route.clear()
  };

  const onDragstart = (item) => {
    const exist = items.some((x) => x.attrs.id === item.id);
    tempItem.value = exist ? null : item;
  };

  const resetTempItem = () => {
    tempItem.value = null;
  };

  const updateIdx = (ids) => {
    items.forEach((x) => {
      const idx = ids.indexOf(x.attrs.meta.id);
      if (idx > -1) {
        x.updateIdx(idx);
      }
    });
  };

  return {
    items,
    has,
    // activeId,
    activeItem,
    tempItem,
    insert,
    resetActiveItem,
    resetTempItem,
    onDragstart,
    updateIdx,
    clearAll,
    // clearItems,
    remove,
    clearWall,
    // select,
    unselect,
  };
};
