<template>
  <el-select v-model="model" clearable size="large" placeholder="可選擇材質">
    <template #prefix>
      <div class="w-6 h-6 overflow-hidden mr-2">
        <img
          v-if="model && opts[model]"
          class="object-cover h-full w-full"
          :src="require(`@/assets/frame/${model}/texture.jpg`)"
          alt=""
        />
        <div v-else class="bg-gray-300 h-full w-full"></div>
      </div>
    </template>
    <el-option
      v-for="(item, key) in opts"
      :key="key"
      :label="item.name"
      :value="key"
    >
      <div class="flex items-center">
        <div class="w-10 h-10 overflow-hidden mr-2">
          <img
            class="object-cover"
            :src="require(`@/assets/frame/${key}/texture.jpg`)"
            alt=""
          />
        </div>
        <div>{{ item.name }}</div>
      </div>
    </el-option>
  </el-select>
</template>
<script>
import { computed } from 'vue';
import { ElSelect, ElOption } from 'element-plus';
import { useVModel } from '@vueuse/core';
import { FRAMES } from '@/utils/Config.js';
export default {
  props: {
    modelValue: { type: String },
    type: { type: String, default: '' },
  },
  components: { ElSelect, ElOption },
  setup(props, { emit }) {
    const opts = computed(() => {
      let result = {};
      Object.keys(FRAMES).forEach((key) => {
        result[key] = FRAMES[key];
      });

      return result;
    });
    const model = useVModel(props, 'modelValue', emit);
    return { model, opts };
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .el-input--prefix {
    .el-input__inner {
      // padding-left: 40px !important;
    }
  }
}
.el-select-dropdown__item {
  height: auto;
  @apply py-2;
}
</style>
