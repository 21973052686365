<template>
  <div class="flex" v-loading="loading">
    <div class="w-[800px] px-8">
      <h2 class="text-primary font-bold mb-2">位置預覽</h2>
      <Pos-Canvas
        v-if="imgObj && form.l && form.h"
        :type="'work'"
        :l="form.l"
        :h="form.h"
        :cover="imgObj"
        :offset-x="form.offsetX"
        :offset-y="form.offsetY"
        :maxheight="extra.depth"
        @update="updateOffset"
      />
      <Offset :offset-x="form.offsetX" :offset-y="form.offsetY"></Offset>
      <p class="text-gray-400 text-xs mt-2">
        直接在上方預覽圖內拖曳展品移動位置
      </p>
    </div>

    <div class="flex-grow px-8">
      <h2 class="text-primary font-bold mb-2">內容</h2>
      <Info :row="work"></Info>

      <Materials v-if="work.materials" :rows="work.materials" />

      <El-form label-position="top" :model="form" ref="formRef" :rules="rules">
        <El-form-item prop="title" required label="展品標題">
          <El-Input v-model="form.title" placeholder="展品標題" />
        </El-form-item>
        <El-form-item prop="excerpt" label="展品說明">
          <El-Input
            placeholder="展品描述"
            type="textarea"
            :rows="10"
            v-model="form.excerpt"
            :debounce="1000"
          />
        </El-form-item>

        <El-form-item prop="excerpt" label="展品外框">
          <Frame
            type="base"
            class="ml-2 h-[42px]"
            v-model="form.extra.frame"
          ></Frame>
        </El-form-item>
        <El-form-item
          v-if="form.type === 3"
          label="展台顏色或材質"
          prop="color"
        >
          <div class="flex">
            <div class="flex">
              <span class="border-gray-800 border px-2 whitespace-nowrap">{{
                form.extra.color
              }}</span>
              <El-Color-Picker
                class="border-gray-800 border h-[42px]"
                v-model="form.extra.color"
              >
              </El-Color-Picker>
            </div>
            <Texture
              type="base"
              class="ml-2 h-[42px]"
              v-model="form.extra.texture"
            ></Texture>
          </div>
        </El-form-item>
      </El-form>
    </div>

    <div class="w-3/12 px-8">
      <h3 class="text-primary font-bold mb-2">參考資訊</h3>

      <History
        v-if="form.work_id"
        :work_id="form.work_id"
        :chip_id="form.id"
      ></History>
    </div>
  </div>
  <div class="action flex justify-center my-8">
    <El-button @click.prevent="submit" type="primary" :loading="loading"
      >儲存設定並關閉</El-button
    >
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import PosCanvas from './_Canvas.vue';
import Offset from './_Offest.vue';
import useForm from './useForm';
import Materials from '@/components/Materials';
import Info from '@/partials/detail/_Info.vue';
import {
  ElButton,
  ElFormItem,
  ElForm,
  ElInput,
  ElColorPicker,
} from 'element-plus';

import useWork from '@/pages/index/components/useWork';
import History from '@/partials/History.vue';
import Texture from '@/pages/index/components/texture/Index.vue';
import Frame from '@/pages/index/components/frame/Index.vue';
import { uploadUrl } from '@/utils/Utils';
export default {
  components: {
    History,
    ElColorPicker,
    ElButton,
    ElFormItem,
    ElForm,
    ElInput,
    PosCanvas,
    Offset,
    Materials,
    Info,
    Texture,
    Frame,
  },
  setup() {
    const dialog = inject('dialog');
    const chosen = inject('chosen');

    const selection = inject('selection');
    const { extra } = inject('blueprint');
    const rules = {};
    const formRef = ref();
    const { form, updateOffset, fetcher } = useForm({
      id: chosen.state.id,
      l: 0,
      w: 10,
      h: 0,
      offsetX: 0,
      offsetY: 0,
      cover: '',
      pic: '',
      title: '',
      type: 5,
      excerpt: '',
      // color: '#333333',
      work_id: null,
      extra: {
        color: '#333333',
        texture: '',
        frame: '',
      },
    });
    const { data: work, fetcher: workFetcher } = useWork();
    const imgObj = ref(null);
    const converting = ref(false);

    const convertImg = (url) => {
      converting.value = true;
      const img = new Image();
      img.src = uploadUrl(url);
      img.onload = () => {
        form.h = (img.height / img.width) * form.l;
        converting.value = false;
        imgObj.value = img;
      };
    };

    fetcher().then(() => {
      workFetcher(form.work_id);
      convertImg(form.cover || form.pic);
    });

    const submit = () => {
      let isValid = true;
      formRef.value.validate((valid) => {
        isValid = valid;
      });
      if (!isValid) return;

      selection
        .update({
          title: form.title,
          id: form.id,
          h: form.h,
          l: form.l,
          offsetX: form.offsetX,
          offsetY: form.offsetY,
          excerpt: form.excerpt?.trim(),
          color: form.extra.color,
          extra: JSON.stringify(form.extra),
        })
        .then(() => {
          dialog.toggle();
        });
    };

    return {
      work,
      imgObj,
      form,
      formRef,
      updateOffset,
      submit,
      rules,
      loading: selection.loading,
      extra,
    };
  },
};
</script>
