import Item from './Item';
import { GRID, STAGE_SIZE } from '@/utils/Config';

import { getGridIdx } from '@/utils/Utils';
export default class Work extends Item {
  constructor(params) {
    super(params);
  }
  update() {
    this.updateSpot();
  }
}
